import * as React from 'react'
import { Link } from 'gatsby'

import Seo from '../../components/common/seo'
import { AuthFlowWrapper } from './layout'
import { LayoutLoggedIn } from '../../components/views/layout'
import { isLoggedIn } from '../../common/utils/auth'
import { Paths } from '../../constants/structure'

const NotFoundMessage: React.FC<{ isLoggedIn?: boolean }> = ({
   isLoggedIn = false,
}) => (
   <div>
      <Seo title="Page not found" />
      {isLoggedIn && <h1>Page not found</h1>}
      <p>The page you are looking for is not available. This may be because:</p>
      <ul>
         <li>it is out of date and has been deleted;</li>
         <li>it has moved; or</li>
         <li>the webpage address was entered incorrectly.</li>
      </ul>
      <Link to={isLoggedIn ? Paths.DASHBOARD : Paths.LANDING}>
         Return to home
      </Link>
   </div>
)

export default function () {
   const Layout1 = () => (
      <LayoutLoggedIn>
         <NotFoundMessage isLoggedIn />
      </LayoutLoggedIn>
   )

   const Layout2 = () => (
      <AuthFlowWrapper heading={<>Page not found</>}>
         <NotFoundMessage />
      </AuthFlowWrapper>
   )

   return <>{isLoggedIn() ? Layout1() : Layout2()}</>
}
